import React from 'react';
import logo from './media/logo-transparent.png'

const MaintenanceMessage = ({ estimatedEndTime }) => {
  return (
    <div style={{ backgroundColor: 'white', border: '2px solid #FFD700', borderRadius: '5px', padding: '20px', textAlign: 'center' }}>
      <img style= {{ padding: '50px 0px 50px', height: '300px' }} src={logo} alt="Not found!"/>
      <h2 style={{ color: '#FFD700' }}>Wartungshinweis</h2>
      <p>Unsere Website befindet sich derzeit in Wartung, um Verbesserungen vorzunehmen.</p>
      <p>Wir sind bald wieder für Sie erreichbar. Vielen Dank für Ihr Verständnis!</p>
      {estimatedEndTime && (
        <div style={{ backgroundColor: '#008000', padding: '10px', marginTop: '20px', borderRadius: '5px' }}>
          Voraussichtliche Fertigstellung: 01.07.24
        </div>
      )}
    </div>
  );
};

export default MaintenanceMessage;
