import MaintenanceMessage from './components/MaintenanceMessage/MaintenanceMessage';

import './App.css';

function App() {
  return (
      
    <MaintenanceMessage />
          
  );
}

export default App;
